<template>
  <PageWrapper
    :active-page="PAGE_COMMUNITY_COMMUNITY"
    :active-section="SECTION_COMMUNITY"
    :breadcrumbs="breadcrumbs"
    align="left"
    page-name="Profile"
    sub-title=""
    width="fixed"
  >
    <ProfileWrapper
      :details="orgProfileData.org_description"
      :logo-image="orgProfileData.logo_url"
      :splash-image="orgProfileData.splash_url"
      :title="orgProfileData.name"
    >
      <template #no-edit-title>
        <iSubHeading>
          <iRow vertical-align="middle" width="hug">
            {{ orgProfileData.name }}
            <iImageContainer
              v-if="hasPublicationCapability"
              v-tooltip="'Creator'"
              :height="30"
              src="/images/influencer-icon.png"
            />
            <iImageContainer
              v-if="hasAdvertiserCapability"
              v-tooltip="'Advertiser'"
              :height="30"
              src="/images/advertiser-icon.png"
            />
          </iRow>
        </iSubHeading>
      </template>
      <template #menu>
        <iRow vertical-align="middle" width="hug" wrap="nowrap">
          <iButton v-if="hasAdvertiserCapability || hasPublicationCapability" size="small" variant="secondary">
            Message
          </iButton>
          <iButton
            v-if="hasPublicationCapability"
            size="small"
            variant="secondary"
            @click="openInviteToPublicationModel"
          >
            Invite
          </iButton>
        </iRow>
      </template>
      <iSpace :height="1" />
      <iColumn gap="large">
        <iColumn v-if="hasAdvertiserCapability && orgProfileData.domains.length" gap="small">
          <iSubHeading font-size="extraLarge" variant="standard">
            Advertiser Websites
          </iSubHeading>
          <iText>
            Visit these websites to find products you may want to recommend to your followers.
          </iText>
          <iColumn gap="extraSmall">
            <iLink
              v-for="(domain, index) in orgProfileData.domains"
              :key="index"
              :href="formatDomain(domain.domain)"
              target="_blank"
            >
              {{ domain.domain }}
            </iLink>
          </iColumn>
        </iColumn>
        <iColumn gap="large">
          <iColumn v-if="hasAdvertiserCapability && orgProfileData.payouts_and_referral_periods.length" gap="small">
            <iSubHeading font-size="extraLarge" variant="standard">
              Affiliate Payout
            </iSubHeading>
            <iColumn gap="extraSmall">
              <iText v-for="payoutString in payouts" :key="payoutString">
                {{ payoutString }}
              </iText>
            </iColumn>
          </iColumn>
          <OrgTeam v-if="activeUsers.length > 0" :org-id="orgId" />
          <iDataTable
            v-if="isAdvertiser"
            :fields="fields"
            :has-more="false"
            :items="advertiserDetails.domains"
            primary-key="advertiser_org_id"
          >
            <template #td(domain)="{data}">
              <iLink :href="getNonRelativeDomain(data.domain)" target="_blank">
                {{ data.domain }}
              </iLink>
            </template>
          </iDataTable>
          <iColumn v-if="orgProfilePublications.length > 0" gap="none">
            <iSubHeading>
              Publications
            </iSubHeading>
            <iDataTable
              :fields="publicationFields"
              :has-more="false"
              :items="activePublications"
              primary-key="publication_id"
            >
              <template #td(domain)="slotProps">
                <iLink :wrap="false" :href="getNonRelativeDomain(slotProps.data.domain)" target="_blank">
                  {{ cleanDomain(slotProps.data.domain) }}
                </iLink>
              </template>
              <template #td(actions)="slotProps">
                <iColumn align="right">
                  <ApplyToCollaborate
                    :org-id="orgId"
                    :publication-id="slotProps.data.publication_id"
                    apply-button-text="Apply To Collaborate"
                  />
                </iColumn>
              </template>
            </iDataTable>
          </iColumn>
          <InviteToPublication :org-id="orgId" :org-name="orgProfileData.name" on-platform />
        </iColumn>
      </icolumn>
    </ProfileWrapper>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  SECTION_COMMUNITY,
  PAGE_COMMUNITY_COMMUNITY,
} from "@/constants/nav-constants";
import { mapActions, mapState } from "pinia";
import { useOrganizationStore } from "@/stores/organization-store";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import InviteToPublication from "@/components/publication/InviteToPublication.vue";
import OrgTeam from "@/components/directory/OrgTeam.vue";
import ApplyToCollaborate from "@/components/publication/ApplyToCollaborate.vue";
import { useCollaborationStore } from "@/stores/collaboration-store";
import ProfileWrapper from "@/components/publication/ProfileWrapper";

export default {
  name: "OrgProfilePage",
  components: {
    ProfileWrapper,
    ApplyToCollaborate,
    OrgTeam,
    InviteToPublication,
    PageWrapper,
  },
  props: {
    orgId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_COMMUNITY_COMMUNITY,
      fields: [
        {
          key: "domain",
          label: "Domains",
        },
        {
          key: "rate",
          label: "Rate",
        },
      ],
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
    ...mapState(useOrganizationStore, [
      "loadingOrgDetails",
      "advertiserDetails",
      "loadingAdvertiserDetails",
      "orgProfileData",
      "hasPublicationCapability",
      "hasAdvertiserCapability",
      "activeUsers",
      "getOrgData",
    ]),
    ...mapState(useCreatorPublicationStore, [
      "orgProfilePublications",
    ]),
    isAdvertiser() {
      return this.orgProfileData.domains > 0;
    },
    activeServices() {
      return Object.values(this.orgProfileData.services_offered).filter(service => service.status === "active");
    },
    activePublications() {
      return this.orgProfilePublications.filter(publication => publication.status === "active");
    },
    publicationFields() {
      const fields = [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "domain",
          label: "Domain",
        },
      ];

      if (this.orgId !== this.getOrgData.org_id) {
        fields.push({
          key: "actions",
          label: "",
        });
      }

      return fields;
    },
    payouts() {
      return this.orgProfileData.payouts_and_referral_periods.map(row => {
        const min = row.payout_type === "percentage" ? `${row.payout_value_min}%` : `$${row.payout_value_min}`;
        const max = row.payout_type === "percentage" ? `${row.payout_value_max}%` : `$${row.payout_value_max}`;
        return min === max ? min : `${min} - ${max}`;
      });
    },
  },
  methods: {
    ...mapActions(useCollaborationStore, [
      "openInviteToPublicationModel",
    ]),
    getNonRelativeDomain(domain) {
      return `//${domain}`;
    },
    formatDomain(domain) {
      if (domain.startsWith("http", 0)) {
        return domain;
      }
      return `https://${domain}`;
    },
    cleanDomain(domain) {
      let formatted = domain.replace("http://", "");
      formatted = formatted.replace("https://", "");

      return formatted;
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-display {
  display: none;
  @include phone {
    display: block;
  }
}

.not-mobile-display {
  display: block;
  @include phone {
    display: none;
  }
}
</style>
