import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { links } from "%/enums/link-enums";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const useAffiliateLinkStore = defineStore("affiliate-link-store", {
  state: () => ({
    lastAffiliateLinkId: 0,
    pageSize: 20,
    affiliateLinks: {},
    loadingCreateAffiliateLink: false,
    loadingAffiliateLinks: false,
    loadingEditAffiliateLink: false,
    affiliateLinkError: "",
    editingRowKey: 0,
    editingRowColumn: "",
    editingValue: "",
    createdAffiliateLink: {},
    loadingFetchAffiliateLinkCollections: false,
    loadingFetchCollectionsContainingLink: false,
    loadingAffiliateLinkCollection: false,
    loadingAddLinkToCollection: false,
    loadingRemoveLinkFromCollection: false,
    collections: [],
    collectionsContainingLink: [],
    collectionsWithSelectedStatusForLink: [],
    errorCreateAffiliateLinkCollection: "",
    errorAddLinkToCollection: "",
    errorRemoveLinkFromCollection: "",
    errorFetchCollectionsContainingLink: "",
    errorFetchOrgCollections: "",
    uploadedAffiliateLinkCollectionCoverUrl: "",
    affiliateLinkCollectionId: 0,
    usingSmartFeed: false,
    affiliateLink: {},
    isAddToCollectionModalOpen: false,
    affiliateDropdownId: -1,
    isCreateLinkModalOpen: false,
  }),
  getters: {
    hasMoreAffiliateLinks() {
      return this.lastAffiliateLinkId !== -1;
    },
    affiliateApiV1Url() {
      return useGeneralStore().getLink(links.affiliateApiV1);
    },
    orgId() {
      return useGeneralStore().orgId;
    },
  },
  actions: {
    createLink(landingUrl) {
      this.affiliateLinkError = null;
      this.loadingCreateAffiliateLink = true;
      $axios.post(`${this.affiliateApiV1Url}/publisher-affiliate-link/create`, {
        publisher_link: landingUrl,
        publisher_org_id: this.orgId,
      }).then(response => {
        if (response.data.success) {
          this.createdAffiliateLink = response.data.publisherAffiliateLink;
        } else {
          this.affiliateLinkError = "Unable to create tracking link. Please try again.";
        }
      }).catch(() => {
        this.affiliateLinkError = "Unable to create tracking link. Please try again.";
      }).finally(() => {
        this.loadingCreateAffiliateLink = false;
      });
    },
    fetchAffiliateLinksByCollectionId(collectionId) {
      if (this.loadingAffiliateLinks) {
        return;
      }
      this.loadingAffiliateLinks = true;
      $axios.get(`${this.affiliateApiV1Url}/publisher-affiliate-link/publisher-links/collection/${collectionId}`, {
        params: {
          last_link_id: this.lastAffiliateLinkId,
        },
      }).then(response => {
        let loadedLinks = response.data.publisherAffiliateLinks;
        this.addLinksToAffiliateLinksCollection(loadedLinks, collectionId);
        if (response.data.publisherAffiliateLinks.length > 0) {
          this.lastAffiliateLinkId = Number(response.data.publisherAffiliateLinks.at(-1).publisher_affiliate_link_id);
        } else {
          this.lastAffiliateLinkId = -1;
        }
      }).catch(error => {
        this.lastAffiliateLinkId = -1;
        throw new Error(`Fetch Affiliate Links Error: ${error.message}`);
      }).finally(() => {
        this.loadingAffiliateLinks = false;
      });
    },
    addLinksToAffiliateLinksCollection(loadedLinks, collectionId) {
      let collection = this.affiliateLinks[collectionId] ?? [];
      loadedLinks.forEach(link => {
        if (!collection.some(checkLink => checkLink.publisher_affiliate_link_id === link.publisher_affiliate_link_id)) {
          if (link.status === "active") {
            collection.push(link);
          }
        }
      });
      this.affiliateLinks[collectionId] = collection;
    },
    fetchAffiliateLinksByOrgId() {
      if (this.loadingAffiliateLinks) {
        return;
      }
      this.loadingAffiliateLinks = true;
      $axios.get(`${this.affiliateApiV1Url}/publisher-affiliate-link/publisher-links/org/${this.orgId}`, {
        params: {
          last_link_id: this.lastAffiliateLinkId,
        },
      }).then(response => {
        let loadedLinks = response.data.publisherAffiliateLinks;
        this.addLinksToAffiliateLinksCollection(loadedLinks, 0);
        if (response.data.publisherAffiliateLinks.length > 0) {
          this.lastAffiliateLinkId = Number(response.data.publisherAffiliateLinks.at(-1).publisher_affiliate_link_id);
        } else {
          this.lastAffiliateLinkId = -1;
        }
      }).catch(error => {
        throw new Error(`Fetch Affiliate Links Error: ${error.message}`);
      }).finally(() => {
        this.loadingAffiliateLinks = false;
      });
    },
    fetchAllCollectionsContainingLink(affiliateLinkId) {
      this.loadingFetchCollectionsContainingLink = true;
      return $axios.get(`${this.affiliateApiV1Url}/affiliate-link-collection/affiliate-link/${affiliateLinkId}`).then(response => {
        this.collectionsContainingLink = response.data.collectionsContainingLink;
        this.collectionsContainingLink.forEach(collection => {
          let collectionId = collection.affiliate_link_collection_id;
          if (!this.affiliateLinks[collectionId]) {
            this.affiliateLinks[collectionId] = collection.links;
          } else {
            collection.links.forEach(link => {
              if (!this.affiliateLinks[collectionId].some(checkLink => checkLink.publisher_affiliate_link_id === link.publisher_affiliate_link_id)) {
                this.affiliateLinks[collectionId].push(link);
              }
            });
          }
        });
        return this.collectionsContainingLink;
      }).catch(() => {
        this.errorFetchCollectionsContainingLink = "There was a problem getting the collections that contain this link";
      }).finally(() => {
        this.loadingFetchCollectionsContainingLink = false;
      });
    },
    fetchMyOrgLinkCollections() {
      this.loadingFetchAffiliateLinkCollections = true;
      return $axios.get(`${this.affiliateApiV1Url}/affiliate-link-collection/org/${this.orgId}`).then(response => {
        this.collections = response.data.affiliateLinkCollections;
        return this.collections;
      }).catch(() => {
        this.errorFetchOrgCollections = "There was a problem getting the collections that contain this link";
      }).finally(() => {
        this.loadingFetchAffiliateLinkCollections = false;
      });
    },
    fetchCollectionsWithSelectedStatusForLink(affiliateLinkId) {
      this.fetchMyOrgLinkCollections().then(() => {
        return this.fetchAllCollectionsContainingLink(affiliateLinkId);
      }).then(myLinkCollections => {
        this.collectionsWithSelectedStatusForLink = this.collections.map(collection => ({
          ...collection,
          selected: myLinkCollections.some(collectionContainingLink => {
            return collection.affiliate_link_collection_id === collectionContainingLink.affiliate_link_collection_id;
          }),
        }));
      });
    },
    editAffiliateLinkProductDetails(index, publisherAffiliateLinkId, brandName, productName, linkDescription, collectionId) {
      if (this.loadingEditAffiliateLink) {
        return;
      }
      this.loadingEditAffiliateLink = true;
      $axios.post(`${this.affiliateApiV1Url}/publisher-affiliate-link/${publisherAffiliateLinkId}/product-details`, {
        brand_name: brandName,
        product_name: productName,
        link_description: linkDescription,
      }).then(response => {
        if (!this.usingSmartFeed) {
          this.affiliateLinks[collectionId][index] = response.data.publisherAffiliateLinkDetails;
          this.editingValue = "";
          this.editingRowColumn = "";
          this.editingRowKey = 0;
        }
      }).catch(error => {
        throw new Error(`Edit Affiliate Link Error: ${error.message}`);
      }).finally(() => {
        this.loadingEditAffiliateLink = false;
      });
    },
    createAffiliateLinkCollection(name, affiliateLinkId = 0, category = "", description = "", coverImage = "", visibility = "public") {
      this.loadingAffiliateLinkCollection = true;
      return $axios.post(`${this.affiliateApiV1Url}/affiliate-link-collection`, {
        org_id: this.orgId,
        name,
        category,
        description,
        cover_image: coverImage,
        visibility,
        affiliate_link_id: affiliateLinkId,
      }).then(response => {
        let collection = response.data.affiliateLinkCollection;
        this.collections.push(collection);
        if (affiliateLinkId) {
          collection.selected = true;
        }
        this.collectionsWithSelectedStatusForLink.unshift(collection);
        return collection;
      }).catch(() => {
        this.errorCreateAffiliateLinkCollection = "Unable to create collection. Please try again.";
      }).finally(collection => {
        this.loadingAffiliateLinkCollection = false;
        return collection;
      });
    },
    uploadAffiliateLinkCollectionCover(coverImageFile) {
      let formData = new FormData();
      formData.append("cover", coverImageFile);
      $axios.post(`${this.affiliateApiV1Url}/affiliate-link-collection/cover-image/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
        if (response.data.success) {
          this.uploadedAffiliateLinkCollectionCoverUrl = response.data.coverUrl;
        }
      });
    },
    editAffiliateLinkCollection(affiliateLinkCollectionId, name, category = "", description = "", coverImage = "", visibility = "public") {
      this.loadingAffiliateLinkCollection = true;
      let formData = new FormData();
      formData.append("name", name);
      formData.append("category", category);
      formData.append("description", description);
      formData.append("cover_image", coverImage);
      formData.append("visibility", visibility);
      return $axios.post(`${this.affiliateApiV1Url}/affiliate-link-collection/${affiliateLinkCollectionId}/update`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        let updatedCollection = response.data.affiliateLinkCollection;
        let collectionIndexToUpdate = this.collections.findIndex(collection => {
          return Number(collection.affiliate_link_collection_id) === Number(updatedCollection.affiliate_link_collection_id);
        });
        Object.keys(updatedCollection).forEach(key => {
          this.collections[collectionIndexToUpdate][key] = updatedCollection[key];
        });
      }).catch(() => {
        this.errorCreateAffiliateLinkCollection = "Unable to update collection. Please try again.";
      }).finally(() => {
        return this.loadingAffiliateLinkCollection = false;
      });
    },
    addToAffiliateLinkCollection(affiliateLinkCollectionId, affiliateLinkId) {
      this.loadingAddLinkToCollection = true;
      $axios.post(`${this.affiliateApiV1Url}/affiliate-link-collection/${affiliateLinkCollectionId}/create-item`, {
        affiliate_link_id: affiliateLinkId,
      }).then(response => {
        new SmartFeedEngine().addEntities(response.entities);
      }).catch(() => {
        this.errorAddLinkToCollection = "Unable to add link to collection. Please try again.";
      }).finally(() => {
        this.loadingAddLinkToCollection = false;
      });
    },
    removeFromAffiliateLinkCollection(affiliateLinkCollectionId, affiliateLinkId) {
      this.loadingRemoveLinkFromCollection = true;
      $axios.post(`${this.affiliateApiV1Url}/affiliate-link-collection/${affiliateLinkCollectionId}/remove-item`, {
        affiliate_link_id: affiliateLinkId,
      }).then(response => {
        let indexToRemove = this.affiliateLinks[affiliateLinkCollectionId].findIndex(link => {
          return link.publisher_affiliate_link_id == response.data.affiliateLinkId;
        });
        this.affiliateLinks[affiliateLinkCollectionId].splice(indexToRemove, 1);
      }).catch(() => {
        this.errorRemoveLinkFromCollection = "Unable to remove link from collection. Please try again.";
      }).finally(() => {
        this.loadingRemoveLinkFromCollection = false;
      });
    },
  },
});
