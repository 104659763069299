// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smart-feed-list-container[data-v-ceb230a6] {
  display: flex;
  flex-direction: row;
  gap: 1em;
  flex-wrap: wrap;
}
.smart-feed-list-container > .smart-feed-item[data-v-ceb230a6] {
  display: flex;
  height: 100px;
  width: 200px;
  background-color: lightgray;
  border-radius: 1em;
}
.list[data-v-ceb230a6] {
  flex-direction: column;
  overflow-y: auto;
  max-height: 1000px;
}
.list > .smart-feed-item[data-v-ceb230a6] {
  height: 80px;
  width: 500px;
}
.list > .smart-feed-item[data-v-ceb230a6]:nth-child(5n) {
  display: none;
}
.carousel[data-v-ceb230a6] {
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 1000px;
}
.carousel > .smart-feed-item[data-v-ceb230a6] {
  height: 250px;
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/SmartFeed/scripts/components/SmartFeedPlaceholder.vue","webpack://./SmartFeedPlaceholder.vue"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;ACDF;ADEE;EACE,aAAA;EACA,aAAA;EACA,YAAA;EACA,2BAAA;EACA,kBAAA;ACAJ;ADIA;EACE,sBAAA;EACA,gBAAA;EACA,kBAAA;ACDF;ADGE;EACE,YAAA;EACA,YAAA;ACDJ;ADGI;EACE,aAAA;ACDN;ADOA;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;ACJF;ADME;EACE,aAAA;EACA,cAAA;ACJJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.smart-feed-list-container {\n  display: flex;\n  flex-direction: row;\n  gap: 1em;\n  flex-wrap: wrap;\n  & > .smart-feed-item {\n    display: flex;\n    height: 100px;\n    width: 200px;\n    background-color: lightgray;\n    border-radius: 1em;\n  }\n}\n\n.list {\n  flex-direction: column;\n  overflow-y: auto;\n  max-height: 1000px;\n\n  & > .smart-feed-item {\n    height: 80px;\n    width: 500px;\n\n    &:nth-child(5n) {\n      display: none;\n    }\n  }\n\n}\n\n.carousel {\n  flex-wrap: nowrap;\n  overflow-x: auto;\n  max-width: 1000px;\n\n  & > .smart-feed-item {\n    height: 250px;\n    flex-shrink: 0;\n  }\n}\n",".smart-feed-list-container {\n  display: flex;\n  flex-direction: row;\n  gap: 1em;\n  flex-wrap: wrap;\n}\n.smart-feed-list-container > .smart-feed-item {\n  display: flex;\n  height: 100px;\n  width: 200px;\n  background-color: lightgray;\n  border-radius: 1em;\n}\n\n.list {\n  flex-direction: column;\n  overflow-y: auto;\n  max-height: 1000px;\n}\n.list > .smart-feed-item {\n  height: 80px;\n  width: 500px;\n}\n.list > .smart-feed-item:nth-child(5n) {\n  display: none;\n}\n\n.carousel {\n  flex-wrap: nowrap;\n  overflow-x: auto;\n  max-width: 1000px;\n}\n.carousel > .smart-feed-item {\n  height: 250px;\n  flex-shrink: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
