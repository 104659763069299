import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useGeneralStore } from "%/stores/general-store";

export const useOrgPostVariantFeedStore = defineSmartFeedPiniaStore("org-post-variant-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      lastId: Number.MAX_SAFE_INTEGER,
      orgId: useGeneralStore().orgId,
      parentId: null,
      parentData: {},
    },
    tableFields: [
      { key: "storyTitle", label: "Title" },
      { key: "variantType", label: "Type" },
      { key: "status", label: "Status" },
      { key: "timestampUpdated", label: "Edited" },
      { key: "timestampCreated", label: "Created" },
    ],
  }),
  getters: {
    feedUrl() {
      `/org/${this.metadata.orgId}/post/${this.metadata.parentId}/smart-feed`;
    },
  },
});
