<template>
  <!-- Always show this section if isApplicationsEnabled is true -->
  <template v-if="isApplicationsEnabled">
    <iColumn width="hug">
      <template v-if="collaboratorRelationship === 'none'">
        <iColumn v-if="!isOwningOrg">
          <iSubHeading v-if="showTitle" font-weight="standard">
            Apply To Collaborate
          </iSubHeading>
          <iText v-if="showExplanationText" variant="subtle">
            You can apply to collaborate on this publication and create content together
          </iText>
          <iButton
            :size="buttonSize"
            :variant="buttonVariant"
            @click="openApplyToCollaborateModel"
          >
            {{ applyButtonText }}
          </iButton>
        </iColumn>
      </template>
      <template v-else>
        <iText variant="subtle">
          You are currently a {{ collaboratorRelationship }} of this publication
        </iText>
      </template>
    </iColumn>

    <iModal
      v-if="applyToCollaborateModalState"
      title="Apply to Collaborate"
      :width="600"
      @close="closeApplyToCollaborateModalAndReset"
    >
      <template #body>
        <iColumn gap="large">
          <iSubHeading v-if="showRoleText" width="fill">
            You are applying to be a {{ preSelectedRole }}
          </iSubHeading>
          <iSelect
            v-if="!showRoleText"
            v-model="applyToCollaborateFormData.role"
            :items="ROLE_OPTIONS"
            label="Role"
            width="fill"
          />
          <iTextArea
            v-model="applyToCollaborateFormData.message"
            label="Application Message"
            placeholder="Enter your message here..."
            :rows="5"
            width="fill"
          />

          <iText v-if="applyToCollaborateError" variant="error">
            {{ applyToCollaborateError }}
          </iText>
        </iColumn>
      </template>
      <template #footer>
        <iRow align="between" gap="standard" width="fill">
          <iColumn width="hug">
            <iButton
              variant="secondary"
              @click="closeApplyToCollaborateModalAndReset"
            >
              Cancel
            </iButton>
          </iColumn>
          <iColumn width="hug">
            <iButton
              variant="primary"
              loading-spinner-position="inside"
              :is-loading="applyToCollaborateLoadingState"
              :disabled="isDisabled"
              @click="submitApplication"
            >
              Submit Application
            </iButton>
          </iColumn>
        </iRow>
      </template>
    </iModal>
  </template>
</template>

<script>
import { ROLE_OPTIONS } from "@/constants/collaborator-constants";
import { useCollaborationStore } from "@/stores/collaboration-store";
import { useOrganizationStore } from "@/stores/organization-store";
import { useUserStore } from "@/stores/user-store";
import { Enum } from "@bloglovin/vue-component-library";
import { mapActions, mapState } from "pinia";

export default {
  name: "ApplyToCollaborate",
  props: {
    publicationId: {
      type: Number,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
    showExplanationText: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    applyButtonText: {
      type: String,
      default: "Apply Now",
    },
    buttonSize: {
      type: Enum,
      options: ["small", "standard", "large"],
      default: "standard",
    },
    buttonVariant: {
      type: Enum,
      options: ["primary", "secondary"],
      default: "secondary",
    },
    showRoleText: {
      type: Boolean,
      default: false,
    },
    defaultRole: {
      type: String,
      default: "",
    },
    collaboratorRelationship: {
      type: String,
      default: "none",
    },
    isApplicationsEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ROLE_OPTIONS,
      preSelectedRole: this.defaultRole,
    };
  },
  computed: {
    ...mapState(useUserStore, ["userData", "emailData"]),
    ...mapState(useOrganizationStore, ["getOrgData"]),
    ...mapState(useCollaborationStore, [
      "applyToCollaborateLoadingState",
      "applyToCollaborateModalState",
      "applyToCollaborateFormData",
      "errors",
    ]),
    isOwningOrg() {
      return this.getOrgData.org_id === this.orgId;
    },
    applyToCollaborateError() {
      return this.errors.applyToCollaborateError;
    },
    isFormValid() {
      return (
        (this.applyToCollaborateFormData.role || this.preSelectedRole) &&
          this.applyToCollaborateFormData.message
      );
    },
    isDisabled() {
      return !this.isFormValid || this.applyToCollaborateLoadingState;
    },
  },
  methods: {
    ...mapActions(useCollaborationStore, [
      "closeApplyToCollaborateModalAndReset",
      "applyToCollaborate",
      "openApplyToCollaborateModel",
    ]),
    async submitApplication() {
      await this.applyToCollaborate(
        this.publicationId,
        this.getOrgData.org_id,
        this.emailData.email_address,
        this.userData.first_name + " " + this.userData.last_name,
        this.applyToCollaborateFormData.role.value || this.preSelectedRole,
        this.orgId,
      );
    },
  },
};
</script>
