<template>
  <div :class="['smart-feed-list-container', smartFeedLayout]">
    <div v-for="index of defaultNumberOfSmartFeedItems" :key="index" :class="['smart-feed-item', entityLayout]" />
  </div>
</template>

<script>
import { Enum } from "@bloglovin/vue-component-library";
import BaseSmartFeedEngine from "SmartFeed/smart-feed-engine";

export default {
  name: "SmartFeedPlaceholder",
  props: {
    entityLayout: {
      type: Enum,
      required: true,
      options: [...BaseSmartFeedEngine.VALID_ENTITY_LAYOUTS],
      // @todo add a validator to ensure that default smart feed layout table has default entity layout table and continues to validate.
    },
    smartFeedLayout: {
      type: Enum,
      options: [...BaseSmartFeedEngine.VALID_SMART_FEED_LAYOUTS],
      required: true,
    },
    defaultNumberOfSmartFeedItems: {
      type: Number,
      require: false,
      default: 6,
    },
  },
};
</script>
<style  lang="scss" scoped>
.smart-feed-list-container {
  display: flex;
  flex-direction: row;
  gap: 1em;
  flex-wrap: wrap;
  & > .smart-feed-item {
    display: flex;
    height: 100px;
    width: 200px;
    background-color: lightgray;
    border-radius: 1em;
  }
}

.list {
  flex-direction: column;
  overflow-y: auto;
  max-height: 1000px;

  & > .smart-feed-item {
    height: 80px;
    width: 500px;

    &:nth-child(5n) {
      display: none;
    }
  }

}

.carousel {
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 1000px;

  & > .smart-feed-item {
    height: 250px;
    flex-shrink: 0;
  }
}
</style>
