import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const usePublicationCollaboratorFeedStore = defineSmartFeedPiniaStore("publication-collaborator-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      publicationId: useCreatorPublicationStore().publicationId,
    },
  }),
  getters: {
    feedUrl() {
      return `/publication/${this.metadata.publicationId}/collaborators/smart-feed`;
    },
  },
});
