<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_ANALYTICS"
    title="Publication Analytics"
    :breadcrumbs="breadcrumbs"
    page-name="Analytics"
    align="left"
    width="full"
  >
    <SmartFeed
      :pinia-store="useStoryEngagementFeedStore"
      default-entity-layout="tableRow"
      default-smart-feed-layout="table"
      sticky-headers
      vertical-align="middle"
    >
      <template #empty-state>
        <iText variant="subtle">
          No analytics found.
        </iText>
      </template>
    </SmartFeed>
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_ANALYTICS,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { useStoryEngagementFeedStore } from "SmartFeed/stores/feed/story-engagement-feed-store";
export default {
  name: "PublicationAnalytics",
  components: {
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_ANALYTICS,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, ["orgId", "publicationId"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
  },
  methods: {
    useStoryEngagementFeedStore,
  },
};
</script>

<style scoped>
</style>
