import { toRaw } from "vue";
import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { links } from "%/enums/link-enums";

export const useConversationStore = defineStore("conversation-store", {
  state: () => ({
    // standard payload
    conversation: {},
    allMessages: {},
    orderedMessages: [],
    lastMessageId: 0,
    lastSortId: 0,

    // other stuff
    isLoading: false,
    sendingMessage: false,
    draftMessage: "",
    errorMessage: "",
    pageLimit: 10,
    sortStrategy: "by_date_sent",
    sortDirection: "desc",
    moderationStrategy: "not_required",
    moderationEnabled: false,
    loadingMessages: false,
    hasMoreMessages: true,
    conversationSettings: {
      allowComments: true,
      allowsLike: true,
      allowsReply: true,
      allowsUpvoteAndDownvote: false,
      allowsEditing: true,
      allowsSharing: false,
    },
    isMessageLoading: {},
    messageError: {},
    generalStore: () => ({}),
  }),

  getters: {
    messages() {
      return this.orderedMessages.map(messageId => this.allMessages[messageId]);
    },
    isModerator() {
      // @todo make this actually work
      return false;
    },
    contentApiV1() {
      return useGeneralStore().getLink(links.contentApiV1);
    },
    creatorApiV1() {
      return useGeneralStore().getLink(links.api);
    },
    userData() {
      return useGeneralStore().userData;
    },
  },

  actions: {
    fetchPublicationChatroom(publicationId) {
      let uri = `/conversation/publication-chatroom/${publicationId}`;
      if (this.loadingMessages) {
        return;
      }

      this.loadingMessages = true;
      this.apiGet(
        uri,
        {
          limit: this.pageLimit,
          sort_direction: this.sortDirection,
          sort_strategy: this.sortStrategy,
          last_message_id: this.lastMessageId || undefined,
          last_sort_id: this.lastSortId || undefined,
        },
        data => {
          this.updateConversation(data.conversationData, data.messages, data.metadata);
          this.loadingMessages = false;
          if (data.messages.length > 0) {
            this.hasMoreMessages = true;
          } else {
            this.hasMoreMessages = false;
          }
        },
        () => {
          this.loadingMessages = false;
          this.hasMoreMessages = false;
        },
      );
    },
    sendPublicationChatroomMessage(publicationId) {
      let uri = `/conversation/publication-chatroom/${publicationId}/message`;

      if (!this.isMessageLoading.newMessage) {
        this.isMessageLoading.newMessage = {};
      }
      this.isMessageLoading.newMessage = true;
      this.apiPost(
        uri,
        {
          message: this.draftMessage,
          moderation_strategy: this.moderationStrategy,
        },
        response => {
          this.updateConversation(response.conversationData, response.messages, response.metadata, true);
        },
        null,
        () => {
          this.isMessageLoading.newMessage = false;
        },
      );
    },
    fetchConversation(conversationId, context, entityId, sortDirection = "desc") {
      let uri = `/post/${entityId}/comments`;
      if (conversationId > 0) {
        uri = `/post/conversation/${conversationId}`;
      }

      if (this.loadingMessages) {
        return;
      }

      this.loadingMessages = true;
      this.apiGet(
        this.creatorApiV1 + uri,
        {
          limit: this.pageLimit,
          sort_direction: sortDirection || this.sortDirection,
          sort_strategy: this.sortStrategy,
          last_message_id: this.lastMessageId || undefined,
          last_sort_id: this.lastSortId || undefined,
        },
        data => {
          this.updateConversation(data.conversationData, data.messages, data.metadata);
          this.hasMoreMessages = data.messages.length !== 0;
          this.loadingMessages = false;
        },
        () => {
          this.loadingMessages = false;
          this.hasMoreMessages = false;
        },
      );
    },

    submitSorting(conversationId, context, entityId, sortingStrategy, sortDirection) {
      this.sortStrategy = sortingStrategy;
      this.sortDirection = sortDirection;
      this.fetchConversation(conversationId, context, entityId);
    },

    sendMessage(conversationId, context, entityId) {
      const generalStore = useGeneralStore();
      if (!generalStore.isLoggedIn) {
        generalStore.setRedirectLocation(window.location.href);
        generalStore.redirectTo("/login");
        return;
      }
      let uri = `/post/${conversationId}/conversation`;
      if (conversationId === 0) {
        uri = `/post/${entityId}/comments`;
      }

      if (!this.isMessageLoading.newMessage) {
        this.isMessageLoading.newMessage = {};
      }
      this.isMessageLoading.newMessage = true;
      this.apiPost(this.creatorApiV1 + uri, {
        message: this.draftMessage,
        moderation_strategy: this.moderationStrategy,
      }, data => {
        this.isMessageLoading.newMessage = false;
        this.updateMessages(data.messages, true);
      });
    },

    updateMessages(messages, newMessage) {
      messages.forEach(message => {
        this.addOrReplaceMessage(message, newMessage);
      });
    },

    deleteMessage(messageId) {
      this.postToMessageEndpoint("delete", messageId, {});
    },

    undeleteMessage(messageId) {
      this.postToMessageEndpoint("undelete", messageId, {});
    },

    editMessage(messageId, newMessage) {
      this.postToMessageEndpoint("edit", messageId, { new_message: newMessage });
    },

    replyToMessage(messageId, newMessage) {
      this.postToMessageEndpoint("reply", messageId, { message: newMessage });
    },

    reportMessage(messageId, reason) {
      this.postToMessageEndpoint("report", messageId, { reason });
    },

    likeMessage(messageId) {
      this.postToMessageEndpoint("like", messageId);
    },

    unLikeMessage(messageId) {
      this.postToMessageEndpoint("revoke-like", messageId);
    },

    upVoteMessage(messageId) {
      this.postToMessageEndpoint("upvote", messageId);
    },

    revokeUpVoteMessage(messageId) {
      this.postToMessageEndpoint("revoke-upvote", messageId);
    },

    downVoteMessage(messageId) {
      this.postToMessageEndpoint("downvote", messageId);
    },

    revokeDownVoteMessage(messageId) {
      this.postToMessageEndpoint("revoke-downvote", messageId);
    },

    approveMessage(messageId) {
      this.postToMessageEndpoint("approve", messageId);
    },

    blockMessage(messageId, reason) {
      this.postToMessageEndpoint("block", messageId, { reason });
    },

    escalateMessage(messageId, reason) {
      this.postToMessageEndpoint("escalate", messageId, { reason });
    },

    handleReaction(reaction, messageId) {
      const reactions = this.allMessages[messageId]?.reactions || [];
      const existingReaction = reactions.find(reactionItem => {
        return reactionItem.reaction === reaction &&
        this.userData.user_id === reactionItem.userId;
      });
      if (existingReaction) {
        this.postToMessageEndpoint("revoke-reaction", messageId, { reaction_id: existingReaction.reactionId });
      } else {
        this.postToMessageEndpoint("react", messageId, { reaction: reaction });
      }
    },


    // ////////////////////
    // Helper Functions //
    // ////////////////////

    getFromApi(uri, data, successHandler, errorHandler, finallyHandler) {
      return this.makeApiCall("GET", uri, data, successHandler, errorHandler, finallyHandler);
    },

    postToApi(uri, data, successHandler, errorHandler, finallyHandler) {
      return this.makeApiCall("POST", uri, data, successHandler, errorHandler, finallyHandler);
    },

    makeApiCall(method, uri, data, successHandler, errorHandler, finallyHandler) {
      if (!successHandler) {
        successHandler = response => {
          this.updateConversation(response.conversationData, response.messages, response.metadata);
        };
      }

      if (method === "POST") {
        return this.apiPost(uri, data, successHandler, errorHandler, finallyHandler);
      }
      if (method === "GET") {
        return this.apiGet(uri, data, successHandler, errorHandler, finallyHandler);
      }
      throw new Error("unknown method");
    },

    postToMessageEndpoint(endpoint, messageId, data) {
      const generalStore = useGeneralStore();
      if (!generalStore.isLoggedIn) {
        generalStore.setRedirectLocation(window.location.href);
        generalStore.redirectTo("/login");
        return;
      }
      if (!this.isMessageLoading[messageId]) {
        this.isMessageLoading[messageId] = {};
      }
      this.isMessageLoading[messageId][endpoint] = true;
      this.apiPost(this.creatorApiV1 + `/post/message/${messageId}/${endpoint}`,
        { data },
        result => {
          this.isMessageLoading[messageId][endpoint] = false;
          this.updateMessages(result.messages);
        },
        error => {
          this.isMessageLoading[messageId].error = error;
        });
    },

    saveSettings(settings) {
      this.conversationSettings = settings;
    },

    updateReply(message) {
      const replies = this.allMessages[message.parentId].replies || [];
      if (!replies.find(reply => reply.messageId === message.messageId)) {
        replies.push(Object.assign({}, this.allMessages[message.messageId]));
        this.allMessages[message.parentId].replies = [...replies];
      } else {
        this.allMessages[message.parentId].replies = this.allMessages[message.parentId].replies.map(reply => {
          if (reply.messageId === message.messageId) {
            return message;
          }
          return reply;
        });
      }
    },

    addOrReplaceMessage(message, appendToFront = false) {
      if (!this.allMessages[message.messageId]) {
        this.allMessages[message.messageId] = message;
        if (!message.parentId) {
          if (appendToFront) {
            this.orderedMessages.unshift(message.messageId);
          } else {
            this.orderedMessages.push(message.messageId);
          }
        } else {
          this.updateReply(message);
        }
      } else {
        if (!message.parentId) {
          const originalReplies = Object.assign([], toRaw(this.allMessages[message.messageId].replies || []));
          this.allMessages = {
            ...this.allMessages,
            [message.messageId]: {
              ...this.allMessages[message.messageId],
              ...message,
              replies: originalReplies,
            },
          };
        } else {
          this.updateReply(message);
        }
      }
    },

    getMessageFromError(error) {
      if (error.message && error.message === "something specific happened") {
        return "something specific happened";
      }
      return "something went wrong";
    },

    resetConversation() {
      this.allMessages = {};
      this.orderedMessages = [];
      this.conversation = {};
      this.lastMessageId = null;
      this.lastSortId = null;
    },

    updateConversation(conversationSummary, messages, metadata, newMessage) {
      this.conversation = conversationSummary;
      messages.forEach(message => {
        this.addOrReplaceMessage(message, newMessage);
      });
      if (metadata && metadata.lastMessageId && metadata.lastSortId) {
        this.lastMessageId = metadata.lastMessageId;
        this.lastSortId = metadata.lastSortId;
      }
    },
  },
});
