import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useGeneralStore } from "%/stores/general-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
export const useMySubscriptionPlanFeedStore = defineSmartFeedPiniaStore("my-subscription-plan-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      publicationId: useCreatorPublicationStore().publicationId,
      orgId: useGeneralStore().orgId,
    },
  }),
  getters: {
    feedUrl() {
      return `/subscription/${this.metadata.publicationId}/my-subscription-plans/smart-feed`;
    },
  },
});
