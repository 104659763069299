<template>
  <div
    class="logo"
    @click="onClick"
    @touchstart.prevent="startPressAndHold"
    @touchend.prevent="cancelPressAndHold"
  >
    <img class="logo logo-image" :src="imageMobileSrc" alt="logo">
    <span v-if="showTitle" class="logo-text">inspiration<span class="logo-text-accent">.co</span></span>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    height: {
      type: String,
      required: false,
      default: "60px",
    },
    width: {
      type: String,
      required: false,
      default: "unset",
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      clickCount: 0,
      pressTimer: null,
    };
  },
  computed: {
    imageMobileSrc() {
      return "https://cdn-alpha.inspiration.co/images/windmill-gradient-v2.svg";
    },
  },
  methods: {
    onClick() {
      window.location.href = "/";
    },
  },
};
</script>

<style scoped lang="scss">

.logo {
  height: v-bind(height);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logo-image {
  width: 75px;
}

.logo-text {
  font-size: 30px;
  font-family: Alegreya Sans;
  margin-bottom: 8px;
}

.logo-text-accent {
  color: #0f8b00;
}
</style>
