import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useGeneralStore } from "%/stores/general-store";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useCollaboratingPublicationFeedStore = defineSmartFeedPiniaStore("collaborating-publication-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      publicationOrgId: useGeneralStore().orgId,
    },
  }),
  getters: {
    feedUrl() {
      `/publication/org/${this.metadata.publicationOrgId}/smart-feed/collaboration`;
    },
  },
});
