<template>
  <iRow>
    <iLink :href="`/publication/org/${orgId}`">
      <iSubHeading>My Publications</iSubHeading>
    </iLink>
  </iRow>
  <iRow class="side-nav" height="fill" overflow="scroll">
    <iMenu orientation="vertical" :active-item="activePublicationItem" :items="sideNavPublication" />
  </iRow>
</template>

<script>
import { mapState } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import {
  PAGE_MY_ACTIVE_PUBLICATIONS,
  PAGE_MY_DRAFT_PUBLICATIONS,
  PAGE_MY_INACTIVE_PUBLICATIONS,
  PAGE_CREATE_PUBLICATION,
  PAGE_PUBLICATION_POSTS,
  PAGE_PUBLICATION_CHAT_ROOM,
  PAGE_PUBLICATION_USERS,
  PAGE_PUBLICATION_SETTINGS,
  PAGE_PUBLICATION_BRANDING,
  PAGE_PUBLICATION_FEATURES,
  PAGE_PUBLICATION_DIGITAL_PRODUCTS,
  PAGE_PUBLICATION_DEPLOYMENT,
  PAGE_PUBLICATION_FEED_GROUP_LIST, PAGE_PUBLICATION_PRO_FEATURES,
  PAGE_PUBLICATION_ANALYTICS,
} from "@/constants/nav-constants";
import { useGeneralStore } from "%/stores/general-store";
export default {
  name: "PublicationSideNav",
  props: {
    activeItem: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useGeneralStore, [
      "orgId",
    ]),
    ...mapState(useCreatorPublicationStore, [
      "publicationId",
      "myActivePublications",
      "myCollaboratingPublications",
      "myDraftPublications",
      "myInactivePublications",
    ]),

    myActivePublicationsMapped() {
      return [...this.myActivePublications, ...this.myCollaboratingPublications].map(this.myPublicationMenuItem);
    },
    myCollaboratingPublicationsMapped() {
      return this.myCollaboratingPublications.map(this.myPublicationMenuItem);
    },
    myDraftPublicationsMapped() {
      return this.myDraftPublications.map(this.myPublicationMenuItem);
    },
    myInactivePublicationsMapped() {
      return this.myInactivePublications.map(this.myPublicationMenuItem);
    },
    sideNavPublication() {
      let menuItems = [
        {
          name: this.myMenuItemName(PAGE_CREATE_PUBLICATION, this.orgId),
          label: "Create a New Publication",
          actionValue: `/publication/org/${this.orgId}/create`,
        },
        {
          name: this.myMenuItemName(PAGE_MY_ACTIVE_PUBLICATIONS, this.orgId),
          label: "Active Publications",
          actionValue: `/publication/org/${this.orgId}/publications/active`,
          subItems: this.myActivePublicationsMapped,
        },
        // {
        //   name: this.myMenuItemName(PAGE_COLLABORATING_PUBLICATIONS, this.orgId),
        //   label: "Collaborating Publications",
        //   actionValue: `/publication/org/${this.orgId}/collaboration`,
        //   subItems: this.myCollaboratingPublicationsMapped,
        // },
        {
          name: this.myMenuItemName(PAGE_MY_DRAFT_PUBLICATIONS, this.orgId),
          label: "Draft Publications",
          actionValue: `/publication/org/${this.orgId}/publications/draft`,
          subItems: this.myDraftPublicationsMapped,
        },
        {
          name: this.myMenuItemName(PAGE_MY_INACTIVE_PUBLICATIONS, this.orgId),
          label: "Closed Publications",
          actionValue: `/publication/org/${this.orgId}/publications/inactive`,
          subItems: this.myInactivePublicationsMapped,
        },
      ];
      return menuItems;
    },
    activePublicationItem() {
      if (this.publicationId) {
        return `${this.activeItem}_${this.publicationId}`;
      } else if (this.orgId) {
        return `${this.activeItem}_${this.orgId}`;
      } else {
        return this.activeItem;
      }
    },
  },
  methods: {
    myMenuItemName(name, nameSuffix) {
      return `${name}_${nameSuffix}`;
    },
    myPublicationMenuItem(publication) {
      let publicationMenuItem = {
        name: this.myMenuItemName(publication.name, publication.publication_id),
        label: publication.name,
        actionValue: `/publication/${publication.publication_id}`,
        subItems: this.myPublicationSubMenuItems(publication),
      };

      return publicationMenuItem;
    },
    myPublicationSubMenuItems(publication) {
      return [
        {
          name: this.myMenuItemName(PAGE_PUBLICATION_POSTS, publication.publication_id),
          label: "Stories",
          actionValue: `/publication/${publication.publication_id}/stories`,
        },
        {
          name: this.myMenuItemName(PAGE_PUBLICATION_ANALYTICS, publication.publication_id),
          label: "Analytics",
          actionValue: `/publication/${publication.publication_id}/analytics`,
        },
        {
          name: this.myMenuItemName(PAGE_PUBLICATION_CHAT_ROOM, publication.publication_id),
          label: "Collaborators",
          actionValue: `/publication/${publication.publication_id}/chatroom`,
        },
        {
          name: this.myMenuItemName(PAGE_PUBLICATION_USERS, publication.publication_id),
          label: "My Users",
          actionValue: `/publication/${publication.publication_id}/user-management`,
        },
        {
          name: this.myMenuItemName(PAGE_PUBLICATION_SETTINGS, publication.publication_id),
          label: "Settings",
          actionValue: `/publication/${publication.publication_id}/settings`,
          subItems: [
            {
              name: this.myMenuItemName(PAGE_PUBLICATION_BRANDING, publication.publication_id),
              label: "Branding",
              actionValue: `/publication/${publication.publication_id}/branding`,
            },
            {
              name: this.myMenuItemName(PAGE_PUBLICATION_FEATURES, publication.publication_id),
              label: "Features",
              actionValue: `/publication/${publication.publication_id}/features`,
            },
            {
              name: this.myMenuItemName(PAGE_PUBLICATION_PRO_FEATURES, publication.publication_id),
              label: "Pro Features",
              actionValue: `/publication/${publication.publication_id}/features/pro`,
            },
            {
              name: this.myMenuItemName(PAGE_PUBLICATION_DIGITAL_PRODUCTS, publication.publication_id),
              label: "Digital Products & Subscriptions",
              actionValue: `/publication/${publication.publication_id}/digital-products`,
            },
            {
              name: this.myMenuItemName(PAGE_PUBLICATION_DEPLOYMENT, publication.publication_id),
              label: "Deployment",
              actionValue: `/publication/${publication.publication_id}/deployment`,
            },
            {
              name: this.myMenuItemName(PAGE_PUBLICATION_FEED_GROUP_LIST, publication.publication_id),
              label: "RSS",
              actionValue: `/publication/${publication.publication_id}/feed-groups`,
            },
          ],
        },
      ];
    },
  },
};
</script>
