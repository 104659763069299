<template>
  <PageWrapper
    :active-page="PAGE_CREATE_PUBLICATION"
    :active-section="SECTION_PUBLICATIONS"
    :breadcrumbs="publicationBreadCrumbs"
    align="left"
    page-name="Create Publication"
    title="Create Publication"
    width="full"
  >
    <iRow align="between" vertical-align="top" wrap="wrap">
      <iColumn width="hug">
        <iTextInput v-model="name" :is-loading="isLoadingCreatePublication" placeholder="Publication Name" />
        <iTextArea
          v-model="description"
          :is-loading="isLoadingCreatePublication"
          placeholder="Publication Description"
        />
      </iColumn>
      <iColumn width="hug">
        <iButton
          :disabled="!name || isLoadingCreatePublication"
          :is-loading="isLoadingCreatePublication"
          loading-spinner-position="before"
          @click="submitPublication"
        >
          Add Publication
        </iButton>
      </iColumn>
    </iRow>

    <iRow>
      <iSubHeading>Access Control</iSubHeading>
    </iRow>
    <iRow
      align="evenly"
      gap="large"
      wrap="wrap"
      vertical-align="top"
    >
      <PublicationSettingsCard
        v-for="(settings, key) in signUpSettingsMap"
        :key="key"
        :description="settings.description"
        :title="settings.title"
        :features="settings.features"
        :focused="selectedSettingKey === key"
        @select="selectSetting(key)"
      />
    </iRow>

    <iRow>
      <iSubHeading>
        Select a starting template
      </iSubHeading>
    </iRow>
    <SmartFeed
      :pinia-store="usePublicationTemplateFeedStore"
      default-entity-layout="cardSmall"
      default-smart-feed-layout="grid"
    />
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import PageWrapper from "@/components/PageWrapper";
import { PAGE_CREATE_PUBLICATION, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { usePublicationTemplateFeedStore } from "SmartFeed/stores/feed/publication-template-feed-store";
import PublicationSettingsCard from "@/components/publication/PublicationLoginSettingsCard.vue";
import { SIGN_UP_SETTINGS_MAP } from "@/constants/sign-up-settings-map";

export default {
  name: "CreatePublication",
  components: {
    SmartFeed,
    PageWrapper,
    PublicationSettingsCard,
  },
  data() {
    return {
      PAGE_CREATE_PUBLICATION,
      SECTION_PUBLICATIONS,
      name: "",
      description: "",
      theme: "0",
      selectedSettingKey: "accountNotRequired",
    };
  },
  styleGuide: () => ({
    templateCardPadding: { "size.spacing": "none" },
    templateCardDescriptionPadding: { "size.spacing": "standard" },
    templateCardBorderColor: { "color.border": "dark" },
    templateCardBorderWidth: { "size.border": "thin" },
    templateCardSelectedBackground: { "color.background": "striking" },
    templateCardBorderRadius: { "size.borderRadius": "large" },
    templateCardOverlayTextColor: { "color.font": "light" },
  }),
  computed: {
    ...mapState(useCreatorPublicationStore, ["isLoadingCreatePublication", "orgId"]),
    ...mapState(usePublicationTemplateFeedStore, ["selectedTemplate"]),
    publicationBreadCrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
      ];
    },
    signUpSettingsMap() {
      return SIGN_UP_SETTINGS_MAP;
    },
  },
  methods: {
    ...mapActions(useCreatorPublicationStore, { createPublication: "createPublication" }),
    usePublicationTemplateFeedStore,
    selectSetting(settingKey) {
      this.selectedSettingKey = settingKey;
    },
    submitPublication() {
      const accessControlFeatures = this.signUpSettingsMap[this.selectedSettingKey].features;
      this.createPublication(
        this.name,
        this.description,
        this.selectedTemplate,
        this.theme,
        accessControlFeatures,
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.template-card {
  padding: v-bind("$getStyles.templateCardPadding");
  border: v-bind("$getStyles.templateCardBorderWidth") solid v-bind("$getStyles.templateCardBorderColor");
  border-radius: v-bind("$getStyles.templateCardBorderRadius");
  overflow: hidden;
}

.template-card-description {
  padding: v-bind("$getStyles.templateCardDescriptionPadding");
}

.selected {
  background-color: v-bind("$getStyles.templateCardSelectedBackground");
}

.template-card-overlay {
  background-color: rgba(0, 0, 0, 25%);
  height: 100%;
  border-radius: v-bind("$getStyles.templateCardBorderRadius");
}

.overlay-text {
  color: v-bind("$getStyles.templateCardOverlayTextColor");
}
</style>
