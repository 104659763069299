<template>
  <iStyledCard
    class="card"
    :width="275"
    :description-length="150"
    pointer-cursor-on-hover
    :focused="focused"
    single-column-metrics
    :metrics="metrics"
    show-border
    selectable
    @click="$emit('select')"
  >
    <template #title>
      <iSubHeading font-size="large">
        {{ title }}
      </iSubHeading>
    </template>
    <template #description>
      <iText font-size="small">
        {{ description }}
      </iText>
      <iSpace :height="10" />
    </template>
    <template #metrics>
      <iRow
        v-for="metric in metrics"
        :key="metric.name"
        gap="extraSmall"
        vertical-align="middle"
        wrap="nowrap"
      >
        <iIcon
          v-if="metric.icon"
          :icon="metric.icon"
          :variant="metric.iconVariant || 'primary'"
          disable-hover
        />
        <span class="card-metric-value">
          {{ metric.value }}
        </span>
        <span class="card-metric-name">
          {{ metric.name }}
        </span>
      </iRow>
    </template>
  </iStyledCard>
</template>

<script>
export default {
  name: "PublicationSettingsCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    features: {
      type: Object,
      required: true,
    },
    focused: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["select"],
  data() {
    return {
      canSignUp: "Allow Signups",
      isPrivatePublication: "Private Publication",
      requireAccountApproval: "Require Approval",
    };
  },
  computed: {
    metrics() {
      return Object.entries(this.features).map(feature => ({
        name: this[feature[0]],
        icon: feature[1] ? "check-circle" : "close",
        iconVariant: feature[1] ? "branded" : "error",
      }));
    },
  },
};
</script>

<style scoped>
.card {
  padding: 8px;
}
</style>
