import { usePublicationStore } from "@/stores/publication-store";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const useMyPublicationDigitalProductFeedStore =
  defineSmartFeedPiniaStore("my-publication-digital-product-feed-store", {
    state: () => ({
      smartFeedEngine: new SmartFeedEngine(),
      metadata: {
        publicationId: usePublicationStore().publicationId,
      },
    }),
    getters: {
      feedUrl() {
        return `/publication-digital-products/publication/${this.metadata.publicationId}/smart-feed`;
      },
    },
  });
