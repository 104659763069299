<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_LINK_GENERATOR"
    title="Create Link"
    :breadcrumbs="breadcrumbs"
    page-name="Link Generator"
    align="left"
    width="fixed"
  >
    <LinkGeneratorWidget />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { mapState } from "pinia";
import { SECTION_MONETIZATION, PAGE_LINK_GENERATOR } from "@/constants/nav-constants";
import LinkGeneratorWidget from "@/components/LinkGeneratorWidget.vue";
import { useAffiliateLinkStore } from "@/stores/affiliate-link-store";

export default {
  name: "LinkGenerator",
  components: {
    PageWrapper,
    LinkGeneratorWidget,
  },
  props: {},
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_LINK_GENERATOR,
      landingUrl: "",
    };
  },
  computed: {
    ...mapState(useAffiliateLinkStore, [
      "orgId",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/monetization/org/${this.orgId}`, label: "Monetization" },
      ];
    },
  },
};
</script>

<style scoped>
</style>
