import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { links } from "%/enums/link-enums";
import { useGeneralStore } from "%/stores/general-store";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useCompanyUsersFeedStore = defineSmartFeedPiniaStore("company-users-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      orgId: useGeneralStore().orgId,
    },
    tableFields: [
      { key: "emailAddress", label: "Email", width: "30%" },
      { key: "fullName", label: "Name", width: "20%" },
      { key: "roleId", label: "Role" },
      { key: "status", label: "Status", width: "90px" },
      { key: "actions", label: "" },
    ],
  }),
  getters: {
    usersApiV1() {
      return useGeneralStore().getLink(links.usersApiV1);
    },
    feedUrl() {
      return `${this.usersApiV1}/org/${this.metadata.orgId}/users/smart-feed`;
    },
  },
});
