<template>
  <iTextInput
    v-model="landingUrl"
    name="landingUrl"
    placeholder="Product URL"
    label="Product URL"
    :required="false"
    type="text"
    @enter="submit"
  />
  <iButton
    type="submit"
    :disabled="loadingCreateAffiliateLink || !landingUrl"
    :is-loading="loadingCreateAffiliateLink"
    loading-text="This might take a minute"
    @click="submit"
  >
    Create Link
  </iButton>
  <template v-if="affiliateLinkError">
    <iText variant="subtle">
      {{ affiliateLinkError }}
    </iText>
  </template>
  <iCopyText
    v-if="createdAffiliateLink.short_link"
    :text="createdAffiliateLink.short_link"
    copy-message="Link Copied!"
    message-position="right"
  />
  <iRow v-if="createdAffiliateLink.affiliate_link_id" :width="500">
    <iSubHeading>
      Add to Collection
    </iSubHeading>
    <SelectAffiliateCollection :affiliate-link-id="createdAffiliateLink.affiliate_link_id" />
  </iRow>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useAffiliateLinkStore } from "@/stores/affiliate-link-store";
import SelectAffiliateCollection from "@/components/SelectAffiliateCollection.vue";

export default {
  name: "LinkGeneratorWidget",
  components: {
    SelectAffiliateCollection,
  },
  props: {},
  data() {
    return {
      landingUrl: "",
    };
  },
  computed: {
    ...mapState(useAffiliateLinkStore, [
      "createdAffiliateLink",
      "affiliateLinkError",
      "loadingCreateAffiliateLink",
      "orgId",
    ]),
  },
  methods: {
    ...mapActions(useAffiliateLinkStore, [
      "createLink",
    ]),
    submit() {
      if (!this.landingUrl) {
        return;
      }
      this.createLink(this.landingUrl);
    },
  },
};
</script>

<style scoped>
</style>
