import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useGeneralStore } from "%/stores/general-store";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useOrgApplicationFeedStore = defineSmartFeedPiniaStore("org-application-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      orgId: useGeneralStore().orgId,
    },
  }),
  getters: {
    feedUrl() {
      return `/org/${this.metadata.orgId}/applications/smart-feed`;
    },
  },
});
