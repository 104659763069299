import { useGeneralStore } from "%/stores/general-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useInspirationNetworkFeedStore = defineSmartFeedPiniaStore("inspiration-network-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    searchQuery: "",
    metadata: {
      lastAdvertiserName: "",
      publisherOrgId: useGeneralStore().orgId,
    },
    tableFields: [
      {
        key: "advertiserName",
        label: "Name",
      },
      {
        key: "payout",
        label: "Payout",
      },
      {
        key: "domains",
        label: "Domains",
      },
    ],
    feedUrl: "/partnership/inspiration/smart-feed",
  }),
  getters: {
    smartFeedRequestGetParams() {
      return {
        last_advertiser_name: this.metadata.lastAdvertiserName,
        search_query: this.searchQuery,
        publisher_org_id: this.metadata.publisherOrgId,
      };
    },
  },
});

