import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useFanPublicationStore } from "%/stores/fan-publication-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const usePostFeedStore = defineSmartFeedPiniaStore("post-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      lastId: Number.MAX_SAFE_INTEGER,
      publicationId: useFanPublicationStore().publicationId,
    },
  }),
  getters: {
    feedUrl() {
      return `publication/${this.metadata.publicationId}/stories/smart-feed`;
    },
  },
});
