import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";

export const useStoryEngagementFeedStore = defineSmartFeedPiniaStore("story-engagement-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      publicationId: useCreatorPublicationStore().publicationId,
    },
    tableFields: [
      { key: "storyName", label: "Story Title", width: "25%" },
      { key: "totalViews", label: "Total Views", width: "15%" },
      { key: "percentageOfTotal", label: "% of Total", width: "15%" },
    ],
  }),
  getters: {
    feedUrl() {
      return `/engagement/${this.metadata.publicationId}/smart-feed`;
    },
  },
});
