<template>
  <iRow>
    <iSubHeading>Monetization</iSubHeading>
  </iRow>
  <iRow class="side-nav" overflow="scroll">
    <iMenu orientation="vertical" :active-item="activeItem" :items="parsedMonetizationNav" />
  </iRow>
</template>

<script>
import { PAGE_LINKS_COLLECTIONS, PAGE_LINKS_REPORT, SIDE_NAV_MONETIZATION } from "@/constants/nav-constants";
import { parseUrlVars } from "@/helpers/parseUrlVars";
import { mapActions, mapState } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { filterMenuItems } from "@/helpers/capabilities-helper";
import { useAffiliateLinkStore } from "@/stores/affiliate-link-store";

const SIDE_NAV_LINK_COLLECTIONS = {
  name: PAGE_LINKS_COLLECTIONS,
  label: "Collections",
  actionValue: "/monetization/org/{org_id}/links/collections",
};

export default {
  name: "MonetizationSideNav",
  props: {
    activeItem: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      PAGE_LINKS_REPORT,
      SIDE_NAV_LINK_COLLECTIONS,
    };
  },
  computed: {
    ...mapState(useAffiliateLinkStore, [
      "collections",
      "orgId",
    ]),
    ...mapState(useGeneralStore, ["orgId"]),
    collectionsLinks() {
      return this.collections.map(collection => {
        return {
          name: collection.name,
          label: collection.name,
          actionValue: `/monetization/org/${this.orgId}/links/collection/${collection.affiliate_link_collection_id}`,
        };
      });
    },
    parsedMonetizationNav() {
      let SideNavigation = SIDE_NAV_MONETIZATION;

      SideNavigation[0].subItems.forEach(subPage => {
        if (subPage.name === PAGE_LINKS_REPORT) {
          let subItems = this.collectionsLinks;
          subItems.unshift(SIDE_NAV_LINK_COLLECTIONS);
          subPage.subItems = subItems;
        }
      });

      const filteredNavItems = filterMenuItems(SideNavigation);
      return parseUrlVars(filteredNavItems, { "{org_id}": this.orgId });
    },
  },
  created() {
    // this.fetchMyOrgLinkCollections();
  },
  methods: {
    ...mapActions(useAffiliateLinkStore, ["fetchMyOrgLinkCollections"]),
  },
};
</script>
