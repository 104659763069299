import { useGeneralStore } from "%/stores/general-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useOrgPostFeedStore = defineSmartFeedPiniaStore("org-post-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      lastId: Number.MAX_SAFE_INTEGER,
      orgId: useGeneralStore().orgId,
    },
    tableFields: [
      { key: "storyTitle", label: "Title" },
      { key: "publishingPlatformName", label: "Publishing Platform" },
      { key: "status", label: "Status" },
      { key: "variants", label: "Variants" },
      { key: "timestampUpdated", label: "Edited" },
      { key: "timestampCreated", label: "Created" },
      { key: "actions", label: "" },
    ],
  }),
  getters: {
    feedUrl() {
      return `/org/${this.metadata.orgId}/post/smart-feed`;
    },
  },
});
