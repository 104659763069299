import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useFanPublicationStore } from "%/stores/fan-publication-store";

export const useMyPublicationCollaborationAdFeedStore =
  defineSmartFeedPiniaStore("my-publication-collaboration-ad-feed-store", {
    state: () => ({
      smartFeedEngine: new SmartFeedEngine(),
      metadata: {
        publicationId: useFanPublicationStore().publicationId,
      },
    }),
    getters: {
      feedUrl() {
        return `/publication-ads/publication/${this.metadata.publicationId}/smart-feed`;
      },
    },
  });
