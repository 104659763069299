import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useFanPublicationStore } from "%/stores/fan-publication-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const useLovedStoryFeedStore = defineSmartFeedPiniaStore("loved-story-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      publicationId: useFanPublicationStore().publicationId,
    },
  }),
  getters: {
    feedUrl() {
      return `publication/${this.metadata.publicationId}/stories/loved/smart-feed`;
    },
  },
});
