import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useFanPublicationStore } from "%/stores/fan-publication-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const useMyDigitalProductPurchaseFeedStore = defineSmartFeedPiniaStore("my-digital-product-purchase-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      publicationId: useFanPublicationStore().publicationId,
    },
  }),
  getters: {
    feedUrl() {
      return `/digital-product-purchase/publication/${this.metadata.publicationId}/purchases/smart-feed`;
    },
  },
});
