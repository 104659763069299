import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";

export const useFeedGroupFeedStore = defineSmartFeedPiniaStore("feed-group-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      lastId: Number.MAX_SAFE_INTEGER,
      publicationId: useCreatorPublicationStore().publicationId,
    },
  }),
  getters: {
    feedUrl() {
      return `/feeds/publication/${this.metadata.publicationId}/feed-groups/smart-feed`;
    },
  },
});
