import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useHomeCommunityPostFeedStore = defineSmartFeedPiniaStore("home-community-post-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    feedUrl: "/posts/home/smart-feed",
    feed: [],
    metadata: {
      lastId: Number.MAX_SAFE_INTEGER,
    },
  }),
});
