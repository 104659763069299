import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";

export const useMyPublicationUsersFeedStore = defineSmartFeedPiniaStore("my-publication-users-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      publicationId: useCreatorPublicationStore().publicationId,
    },
    tableFields: [
      {
        key: "fullName",
        label: "Name",
      },
      {
        key: "emailAddress",
        label: "Email",
      },
      {
        key: "status",
        label: "Status",
      },
      {
        key: "dateCreated",
        label: "Created At",
      },
      { key: "actions", label: "" },
    ],
  }),
  getters: {
    feedUrl() {
      return `/publication/my-users/${this.metadata.publicationId}/smart-feed`;
    },
  },
});
