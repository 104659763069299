<template>
  <iRow wrap="nowrap" vertical-align="middle">
    <iEditor
      v-model="draftMessage"
      name="message"
      focus
      placeholder="Type a message..."
      @enter="sendNewMessage"
    />
    <iIcon
      class="send-btn"
      :disabled="newMessageIsLoading"
      :is-loading="newMessageIsLoading"
      icon="message"
      variant="primary"
      size="standard"
      @click="sendNewMessage"
    />
  </iRow>
</template>

<script>
import { useConversationStore } from "%/stores/conversation-store";
import { mapActions, mapState, mapWritableState } from "pinia";
import { DEFAULT_USER_AVATAR } from "%/constants/user-constants";

export default {
  name: "NewChatroomMessage",
  props: {
    userAvatarUrl: {
      type: String,
      required: false,
      default: DEFAULT_USER_AVATAR,
    },
    context: {
      type: String,
      required: false,
      default: "",
    },
    entityId: {
      type: Number,
      required: false,
      default: 0,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapWritableState(useConversationStore, ["draftMessage"]),
    ...mapState(useConversationStore, ["isMessageLoading"]),
    newMessageIsLoading() {
      return this.isMessageLoading.newMessage;
    },
  },
  methods: {
    ...mapActions(useConversationStore, [
      "sendMessage",
      "sendPublicationChatroomMessage",
    ]),
    sendNewMessage() {
      if (!this.draftMessage) {
        return;
      }
      if (this.context === "publication_chatroom") {
        this.sendPublicationChatroomMessage(this.entityId);
      } else {
        this.sendMessage(this.conversationId, this.context, this.entityId);
      }
      this.draftMessage = "";
    },
  },
};
</script>

<style scoped lang="scss">
.send-btn{
  position: absolute;
  right: 12px;
  top:0;
  bottom: 0;
  margin-block: auto;
}

.message-box-container {
  position: relative;
}

.message-box-container :deep(input) {
  padding-right: 36px;
}
</style>
