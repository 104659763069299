import { useGeneralStore } from "%/stores/general-store";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const useActiveSubscriptionFeedStore = defineSmartFeedPiniaStore(
  "subscription-feed-store",
  {
    state: () => ({
      smartFeedEngine: new SmartFeedEngine(),
      metadata: {
        orgId: useGeneralStore().orgId,
        userId: useGeneralStore().userId,
      },
      tableFields: [
        { key: "publication_id", label: "Publication", width: "15%" },
        { key: "date_subscribed", label: "Date Subscribed", width: "15%" },
        { key: "amount", label: "Amount", width: "15%" },
        { key: "billing_frequency", label: "Billing Frequency", width: "15%" },
        { key: "next_billing_date", label: "Next Billing Date", width: "15%" },
      ],
    }),
    getters: {
      feedUrl() {
        return `/subscription/${this.metadata.orgId}/${this.metadata.userId}/active/smart-feed`;
      },
    },
  },
);
