<template>
  <iColumn :id="featureId" gap="large" :width="width">
    <iRow align="between">
      <iHeading class="feature-wrapper-heading" :description="description">
        {{ heading }}
      </iHeading>
      <slot name="feature-actions" />
    </iRow>
    <iRow>
      <slot />
    </iRow>
    <iSpace :height="4" />
  </iColumn>
</template>

<script>
export default {
  name: "PublicationFeatureWrapper",
  props: {
    width: {
      type: [String, Number],
      required: false,
      default: "fill",
    },
    heading: {
      type: String,
      required: false,
      default: "",
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    featureId: {
      type: String,
      required: false,
      default: "feature",
    },
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
:deep(.i-heading) {
  line-height: 1 !important;
}
</style>
