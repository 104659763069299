import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useSyndicationMarketPlaceFeedStore = defineSmartFeedPiniaStore("syndication-marketplace-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      lastId: Number.MAX_SAFE_INTEGER,
    },
    feedUrl: "/story/syndication/marketplace/smartfeed",
  }),
});
