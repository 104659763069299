<template>
  <PageWrapper
    :active-page="PAGE_COMMUNITY_COMMUNITY"
    :active-section="SECTION_COMMUNITY"
    :breadcrumbs="breadcrumbs"
    :page-name="publication.name"
    align="left"
    sub-title=""
    width="full"
  >
    <ProfileWrapper
      :logo-image="publicationLogo"
      :splash-image="publicationSplashImage"
      :title="publication.name"
    >
      <template #menu>
        <ApplyToCollaborate
          :org-id="publication.org_id"
          :publication-id="publication.publication_id"
          :is-applications-enabled="publication.enable_applications === 'enabled'"
          :collaborator-relationship="collaboratorRelationship"
          button-size="small"
        />
      </template>
      <iSpace :height="1" />
      <template #details>
        <iColumn gap="extraSmall" width="hug">
          <iLink :href="domainUrl">
            {{ domainUrl }}
          </iLink>
        </iColumn>
      </template>
      <iStyledCard>
        <template #description>
          <iColumn gap="extraLarge">
            <iColumn v-if="publication.description">
              <iSubHeading>Description</iSubHeading>
              <iText> {{ publication.description }}</iText>
            </iColumn>
            <iColumn v-if="hasCollaborators" wrap="nowrap">
              <iSubHeading>
                Collaborators
              </iSubHeading>
              <SmartFeed :pinia-store="usePublicationCollaboratorFeedStore" default-smart-feed-layout="grid" default-entity-layout="cardSmall" />
            </iColumn>
          </iColumn>
        </template>
      </iStyledCard>
    </ProfileWrapper>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_AFFILIATE_PLATFORM_SETTINGS,
  SECTION_COMMUNITY,
  PAGE_COMMUNITY_COMMUNITY,
} from "@/constants/nav-constants";
import { mapState } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import ApplyToCollaborate from "@/components/publication/ApplyToCollaborate.vue";
import { useCollaborationStore } from "@/stores/collaboration-store";
import ProfileWrapper from "@/components/publication/ProfileWrapper";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { usePublicationCollaboratorFeedStore } from "SmartFeed/stores/feed/publication-collaborator-feed-store";
import { useOrganizationStore } from "@/stores/organization-store";

export default {
  name: "PublicationProfile",
  components: {
    ProfileWrapper,
    ApplyToCollaborate,
    PageWrapper,
    SmartFeed,
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_AFFILIATE_PLATFORM_SETTINGS,
      PAGE_COMMUNITY_COMMUNITY,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, [
      "publication",
      "publicationId",
      "publicationSplashImage",
      "publicationLogo",
    ]),
    ...mapState(useOrganizationStore, ["getOrgData"]),
    ...mapState(useCollaborationStore, ["collaborators"]),
    ...mapState(useCreatorPublicationStore, ["collaboratorRelationship"]),
    hasCollaborators() {
      return usePublicationCollaboratorFeedStore().feed.length > 0;
    },
    domainUrl() {
      if (this.publication.domain) {
        if (this.publication.domain.startsWith("http", 0)) {
          return this.publication.domain;
        }
        return "https://" + this.publication.domain;
      }
      return this.internalDomain;
    },
    internalDomain() {
      return `${window.location.origin}/my-publication/${this.publicationId}`;
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
    isOwningOrg() {
      return this.getOrgData.org_id === this.publication.org_id;
    },
  },
  methods: {
    usePublicationCollaboratorFeedStore,
  },
};
</script>

<style lang="scss" scoped>
</style>
