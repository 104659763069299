import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const usePublicationTemplateFeedStore = defineSmartFeedPiniaStore("publication-template-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    selectedTemplate: 0,
    feedUrl: "/publication/templates/smart-feed",
  }),
  actions: {
    setSelectedTemplate(template) {
      this.selectedTemplate = template;
    },
  },
});
